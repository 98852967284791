<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-minus</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Abwesenheiten / Stv.</v-toolbar-title>
      <v-spacer />
      <SearchField hint="Name oder Kürzel" v-model="search"></SearchField>
      <template v-slot:extension>
        <TermTabs align-with-title v-model="term" />
        <v-tabs>
          <v-tabs-slider :color="current ? 'error' : 'primary'"></v-tabs-slider>
          <v-tab @click="toggleCurrent">aktuelle</v-tab>
        </v-tabs>
        <v-btn @click="add" absolute bottom right color="success" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="itemsFiltered"
        :sort-by="'startDate'"
      >
        <template v-slot:item.teacher="{ item }">
          <PersonItem
            clickable
            nodetails
            :value="item.teacher"
            :class="searchPerson(item.teacher) ? 'primary' : ''"
            :dark="searchPerson(item.teacher)"
          />
        </template>
        <template v-slot:item.substitute="{ item }">
          <v-chip
            v-for="substitute in item.substitutes"
            :key="substitute.id"
            :color="getPersonColor(substitute)"
            :to="{ name: 'Person', params: { id: substitute.id.toString() } }"
            class="ma-1"
          >
            <v-avatar left>
              <PortraitImage :value="substitute" />
            </v-avatar>
            <span>
              {{ substitute.code }}
            </span>
          </v-chip>
        </template>
        <template v-slot:item.startDate="{ item }">
          <DateValue :value="item.startDate" />
        </template>
        <template v-slot:item.endDate="{ item }">
          <DateValue :value="item.endDate" />
        </template>
        <template v-slot:item.substituted="{ item }">
          <v-simple-checkbox :value="item.substituted" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="showDetails(item.id)" icon
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <router-view @dataChanged="getData"></router-view>
  </v-container>
</template>
<script>
import { comparePeople, searchPerson } from "common/utils/people.js";
import { today } from "common/utils/date.js";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem";
import PortraitImage from "common/components/PortraitImage";
import SearchField from "@/components/SearchField";
import TermTabs from "@/components/TermTabs";

export default {
  name: "Absences",
  components: {
    DateValue,
    PersonItem,
    PortraitImage,
    SearchField,
    TermTabs,
  },
  data() {
    return {
      current: false,
      loading: false,
      search: "",
      headers: [
        { text: "Lehrer*in", value: "teacher", sort: comparePeople },
        { text: "Stv(s)", value: "substitute", sortable: false },
        { text: "von", value: "startDate" },
        { text: "bis", value: "endDate" },
        { text: "Grund", value: "absenceCause.description" },
        { text: "Kommentar", value: "comment" },
        { text: "", value: "actions" },
      ],
      items: [],
      term: null,
    };
  },
  computed: {
    itemsFiltered() {
      if (!this.search) {
        return this.items;
      }
      return this.items.filter(
        (item) =>
          searchPerson(item.teacher, this.search) ||
          item.substitutes.some((substitute) =>
            searchPerson(substitute, this.search)
          )
      );
    },
  },
  watch: {
    term() {
      this.current = false;
      this.getData();
    },
  },
  methods: {
    add() {
      this.$router.push({
        name: "TeacherAbsenceDetails",
        params: { id: "new" },
      });
    },
    async getData() {
      if (!this.term && !this.current) return;
      const startDate = this.current ? today() : this.term.startDate;
      const endDate = this.current ? today() : this.term.endDate;
      this.loading = true;
      this.items = await this.apiList({
        resource: "teacher/absence",
        query: `startDate=${startDate}&endDate=${endDate}`,
      });
      this.loading = false;
    },
    searchPerson(substitute) {
      if (!this.search) {
        return false;
      }
      return searchPerson(substitute, this.search);
    },
    toggleCurrent() {
      this.current = !this.current;
      this.getData();
    },
    getPersonColor(substitute) {
      if (!this.search) {
        return "";
      }
      if (searchPerson(substitute, this.search)) {
        return "primary";
      }
      return "";
    },
    getSubstitutesFiltered(substitutes) {
      if (!this.search) {
        return substitutes;
      }
      return substitutes.filter((item) => searchPerson(item, this.search));
    },
    showDetails(id) {
      this.$router.push({
        name: "TeacherAbsenceDetails",
        params: { id: id },
      });
    },
  },
};
</script>
